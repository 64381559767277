import React from 'react';
import { PageProps, graphql } from 'gatsby';

import { withTranslation, WithTranslation, Trans } from 'react-i18next';
import Layout, { LayoutOptions, HeroSectionColors } from '../../../components/Layout/Layout';
import { LocaleNameSpaceKeys } from '../../../localization/translations';
import { useLocaleContext } from '../../../context/Locale';
import ExternalLink from '../../../components/ExternalLink/ExternalLink';
// import { getPathFromRouteKey } from '../../../shared/utils/getPathFromRouteKey';
// import RouteKeys from '../../../../config/RouteKeys';
import ButtonLink from '../../../components/ButtonLink/ButtonLink';
import CommonData from '../../../data/common/common';
import { IconTypes } from '../../../components/Icon/Icon';
import { getTableRows } from '../../../shared/utils/table';

type Props = PageProps & WithTranslation;

const CCPCashback: React.FC<Props> = (props: Props) => {
  const { t, data } = props;
  const { activeLocaleTag } = useLocaleContext();

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t('seo.title'),
    },
    // page: {
    //   title: t('heading'),
    // },
    heroSection: {
      options: {
        accentColor: HeroSectionColors.SKY_BLUE,
        heroImage: {
          fileName: 'hero-image-cashback.jpg',
          alt: 'Image',
        },
      },
    },
  };

  const flmImageUrl = (data as any).banner.edges.find((edge: any) =>
    edge.node.name.includes(`-${activeLocaleTag}`)
  ).node.publicURL;

  return (
    <Layout options={layoutOptions} className="CCP">
      <div className="grid-container">
        <div className="row row-wrap">
          <section className="column column-60">
            {/* <div className="CCP__generic-gap">
              <h6>{t('cashback.introBlock')}</h6>
            </div> */}
            <div className="CCP__generic-gap">
              <h1 className="h4 text-center">{t('cashback.headingFour')}</h1>
              <h2 className="h6">{t('cashback.headingSix1')}</h2>
              <p>
                <Trans t={t} i18nKey="cashback.body1" />
              </p>
            </div>
            {/* <div className="CCP__generic-gap text-center">
              <h4>{t('cashback.headingSix2')}</h4>
              <ButtonLink
                to={process.env.GATSBY_CUSTOMER_PORTAL_URL || '/'}
                linkOptions={{ isExternalLink: true }}
                iconOptions={{ icon: IconTypes.ARROW_NEXT }}
                styleOptions={{ isInline: true }}
              >
                {t('cashback.buttonLabel')}
              </ButtonLink>
            </div> */}
            <div className="CCP__generic-gap">
              <h2 className="h6 text-center" id="cashback-table-heading">
                <Trans t={t} i18nKey="cashback.headingSix2" />
              </h2>
              <table className="mb-2" aria-labelledby="cashback-table-heading">
                <tbody>{getTableRows(t('cashback.table.rows', { returnObjects: true }))}</tbody>
              </table>
              <ButtonLink
                className="mb-2"
                to={CommonData.websites.htb.renewMortgageCampaign[activeLocaleTag]}
                linkOptions={{ isExternalLink: true }}
                aLinkProps={{ target: '_self' }}
                iconOptions={{ icon: IconTypes.ARROW_NEXT }}
                styleOptions={{ isInline: true }}
              >
                {t('cashback.buttonLabel')}
              </ButtonLink>
              <p>
                <Trans
                  t={t}
                  i18nKey="cashback.body2"
                  values={{ phone: CommonData.tollFreeCustomerServiceNumber.mortgageServicing }}
                >
                  <ExternalLink
                    href={`tel:${CommonData.tollFreeCustomerServiceNumber.mortgageServicing}`}
                    aria-label={`${t(`${LocaleNameSpaceKeys.COMMON}:tollFree`)} ${
                      CommonData.tollFreeCustomerServiceNumber.mortgageServicing
                    }`}
                  />
                </Trans>
              </p>
              <p className="small">{t('cashback.footerNote')}</p>
            </div>
          </section>
          <aside className="column column-33">
            <div className="img-container">
              <ExternalLink href={process.env.GATSBY_CUSTOMER_PORTAL_URL}>
                <img src={flmImageUrl} alt={t('cashback.headingFour')} />
              </ExternalLink>
            </div>
          </aside>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    banner: allFile(filter: { relativePath: { regex: "/ccp/cashback//" }, ext: { eq: ".jpg" } }) {
      edges {
        node {
          publicURL
          name
        }
      }
    }
  }
`;

export default withTranslation(LocaleNameSpaceKeys.CCP)(CCPCashback);
